export default function initModal() {
  document.addEventListener("click", (evt) => {
    //handle modal close button
    const modal = evt.target.closest("[data-modal]");
    if (modal) {
      if (evt.target.closest("[data-modal-close]")) {
        modal.remove();
      }

      if (
        evt.target.closest("[data-modal-overlay]") &&
        !evt.target.closest("[data-modal-content]")
      ) {
        modal.remove();
      }
    }
  });
}

export function closeModals() {
  document.querySelectorAll("[data-modal]").forEach((modal) => {
    modal.remove();
  });
}
