import $ from "jquery";

import "select2";
import translations from "select2/src/js/select2/i18n/sl";

const DEFAULT_OPTIONS = {
  language: translations,
  minimumInputLength: 0,
  allowClear: true,
  containerCssClass: "form-control",
  width: "element",
};

export const OTHER = "__other__";

export function isOther(value) {
  return value && value.startsWith(OTHER);
}

export function makePattern(term) {
  const keywords = term.split(/\s/);
  return new RegExp(`(${keywords.join("|")})`, "ig");
}

export function textIncludesAllTerms(text, term) {
  for (const keyword of term.toLowerCase().split(/\s/)) {
    if (!text.toLowerCase().includes(keyword)) {
      return false;
    }
  }
  return true;
}

/**
 * highlightText.
 *
 * @param {} text
 * @param {} term
 */
export function highlightText(text, term) {
  if (!term) {
    return text;
  }
  const pattern = makePattern(term);
  const result = text.replace(pattern, (match) => `<strong>${match}</strong>`);
  return result;
}

/**
 * Wraps DRF results in results, add pagination.
 */
const processResults = (data, otherOption = null) => {
  let res;
  if (data.results) {
    const results = {
      pagination: {
        more: !!data.next,
      },
      results: data.results,
    };
    res = results;
  } else {
    res = { results: data };
  }
  if (otherOption && !res.pagination?.more) {
    res.results.push({
      id: otherOption,
      text: otherOption.split(":", 2)[1],
    });
  }
  return res;
};

const ajaxOptions = (el) => {
  const $el = $(el);
  const multiple = Boolean($el.attr("multiple"));
  const otherOption = $el.attr("data-other-option");
  let currentTerm = "";

  return {
    ...DEFAULT_OPTIONS,
    placeholder: $el.attr("placeholder") || "",
    multiple: multiple,
    templateResult: (state) => {
      if (state.loading) {
        return state.text;
      }
      if (otherOption && state.id && state.id.startsWith(OTHER)) {
        return $(
          '<div style="font-size: 14px; text-decoration: underline;">' +
            state.text +
            "</div>"
        );
      }
      return $(`<span>${highlightText(state.text, currentTerm)}</span>`);
    },
    ajax: {
      url: $el.attr("data-url"),
      dataType: "json",
      data: (params) => {
        currentTerm = params.term;
        const query = {
          ...params,
        };
        return query;
      },
      processResults: (data) => processResults(data, otherOption),
    },
  };
};

const shouldSkip = (el) =>
  el.attributes.name && el.attributes.name.value.indexOf("__prefix__") !== -1;

export function initializeSelect2(el) {
  const $el = $(el);
  const multiple = Boolean($el.attr("multiple"));

  if (shouldSkip(el)) {
    return;
  }

  const options = {
    ...DEFAULT_OPTIONS,
    placeholder: $el.attr("placeholder") || "",
    multiple: multiple,
    templateResult: (state) => {
      const currentTerm = $(
        ".select2-search__field",
        $(el).data("select2").$dropwdown
      ).val();
      return $(`<span>${highlightText(state.text, currentTerm)}</span>`);
    },
  };

  const stickyOption = el.dataset.enableOther;
  if (stickyOption) {
    options.matcher = (params, data) => {
      if ($.trim(params.term) === "") {
        return data;
      }

      if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) !== -1) {
        return data;
      }

      if (data.id == stickyOption) {
        return data;
      }

      return null;
    };
  }

  $(el)
    .select2(options)
    .on("select2:unselecting", function () {
      $(this).data("unselecting", true);
    })
    .on("select2:opening", function (e) {
      if ($(this).data("unselecting")) {
        $(this).removeData("unselecting");
        e.preventDefault();
      }
    });
  $(el).on("change", (evt) => {
    evt.target.closest("form").dispatchEvent(new Event("input"));
  });
}

export function initializeSelect2WithAjax(el) {
  if (shouldSkip(el)) {
    return;
  }

  $(el)
    .select2({
      ...ajaxOptions(el),
      selectionCssClass: "select2-select--remote-field",
    })
    .on("select2:unselecting", function () {
      $(this).data("unselecting", true);
    })
    .on("select2:opening", function (e) {
      if ($(this).data("unselecting")) {
        $(this).removeData("unselecting");
        e.preventDefault();
      }
    });
  $(el).on("change", (evt) => {
    evt.target.closest("form").dispatchEvent(new Event("input"));
  });
}

export const setSelect2Value = (el, data, clean = false) => {
  if (!data) {
    if (clean) {
      el.val(null).trigger("change");
    }
    return;
  }
  // create the option and append to Select2
  var option = new Option(data.text, data.id, true, true);
  el.append(option).trigger("change");

  // manually trigger the `select2:select` event
  el.trigger({
    type: "select2:select",
    params: {
      data: data,
    },
  });
};

export const getRemoteFieldId = (val) => (val ? val.split(":", 1)[0] : null);

export const addAndSelectOption = (el, id) => {
  $.ajax({
    type: "GET",
    url: `${el.get(0).dataset.url}${id}/`,
  }).then(function (data) {
    setSelect2Value(el, data);
  });
};
