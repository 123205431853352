import $ from "jquery";
import {
  checkboxToggleFields,
  fillField,
  getCompanyDetails,
  handleBsfCity,
  handleBsfCompany,
  handleBsfPerson,
  toggleField,
} from "./helpers";
import { getRemoteFieldId, isOther, setSelect2Value } from "./select2";
import { DOMESTIC_COUNTRY_ID } from "./constants";

function handleMainProducer() {
  checkboxToggleFields(
    document.getElementById("id_producers-production_is_person")
  );
  handleBsfCompany(document.getElementById("id_producers-production"));
  handleBsfPerson(document.getElementById("id_producers-production_person"));
}

function handleSloProducers() {
  checkboxToggleFields(
    document.getElementById("id_producers-slo_production_is_person")
  );
  handleBsfCompany(document.getElementById("id_producers-slo_production"));
  handleBsfPerson(
    document.getElementById("id_producers-slo_production_person")
  );
}

function handleNoSloProduction() {
  const SLO_PRODUCENT_FIELDS = [
    "id_producers-slo_production_is_person",
    "id_producers-slo_production",
    "id_producers-slo_production_name",
    "id_producers-slo_production_person",
    "id_producers-slo_production_person_name",
  ];
  const noSloProduction = document.getElementById(
    "id_producers-no_slo_production"
  ).checked;
  if (noSloProduction) {
    handleSloProducers()
    SLO_PRODUCENT_FIELDS.forEach((id) =>
      toggleField(document.getElementById(id), true)
    );
  } else {
    toggleField(
      document.getElementById("id_producers-slo_production_is_person"),
      false
    );
    handleSloProducers();
  }
}

const handleProductionCountry = () => {
  const SLO_PRODUCENT_FIELDS = [
    "id_producers-no_slo_production",
    "id_producers-slo_production_is_person",
    "id_producers-slo_production",
    "id_producers-slo_production_name",
    "id_producers-slo_production_person",
    "id_producers-slo_production_person_name",
  ];
  const productionCountry = document.getElementById(
    "id_producers-production_country"
  );
  const mainProductionDomestic =
    getRemoteFieldId($(productionCountry).val()) ===
    String(DOMESTIC_COUNTRY_ID);
  if (mainProductionDomestic) {
    SLO_PRODUCENT_FIELDS.forEach((id) =>
      toggleField(document.getElementById(id), true)
    );
  } else {
    toggleField(
      document.getElementById("id_producers-no_slo_production"),
      false
    );
    handleNoSloProduction();
  }
};

function fillProducerContactFields(company) {
  console.log(company);
  if (company.is_domestic) {
    fillField($("#id_producers-address"), company.address, true);
    fillField($("#id_producers-zip_code"), company.zip_code, true);
    setSelect2Value($("#id_producers-city"), company.city, true);
    fillField($("#id_producers-tel"), company.phone, true);
    fillField($("#id_producers-email"), company.email, true);
    fillField($("#id_producers-url"), company.website, true);
  }
}

export default function handleProducers() {
  const mainProduction = document.getElementById("id_producers-production");
  handleMainProducer();
  $(document.getElementById("id_producers-production_is_person")).on(
    "change",
    handleMainProducer
  );
  $(mainProduction).on("change", (ev) => handleBsfCompany(ev.target));
  $(document.getElementById("id_producers-production_person")).on(
    "change",
    (ev) => handleBsfPerson(ev.target)
  );

  const productionCountry = document.getElementById(
    "id_producers-production_country"
  );
  $(productionCountry).on("change", handleProductionCountry);
  handleProductionCountry();

  $(document.getElementById("id_producers-no_slo_production")).on(
    "change",
    handleNoSloProduction
  );

  const sloProduction = document.getElementById("id_producers-slo_production");
  $(sloProduction).on("change", handleNoSloProduction);
  $(document.getElementById("id_producers-slo_production_is_person")).on(
    "change",
    handleSloProducers
  );

  $(document.getElementById("id_producers-slo_production")).on("change", (ev) =>
    handleBsfCompany(ev.target)
  );
  $(document.getElementById("id_producers-slo_production_person")).on(
    "change",
    (ev) => handleBsfPerson(ev.target)
  );

  $("#id_producers-production_city").on("select2:select", (evt) => {
    handleBsfCity(evt.target, evt.params.data);
  });

  $(mainProduction).on("select2:select", async (evt) => {
    if (isOther(evt.params.data.id)) {
      return;
    }
    const company = await getCompanyDetails(
      getRemoteFieldId($(mainProduction).val())
    );
    setSelect2Value($("#id_producers-production_city"), company.city, true);
    setSelect2Value(
      $("#id_producers-production_country"),
      company.country,
      true
    );
    fillProducerContactFields(company);
  });
  $(sloProduction).on("select2:select", async (evt) => {
    if (isOther(evt.params.data.id)) {
      return;
    }
    const company = await getCompanyDetails(
      getRemoteFieldId($(sloProduction).val())
    );
    fillProducerContactFields(company);
  });
}
