export default function initBsfImageChooser() {
  document.addEventListener("click", (evt) => {
    const imageEl = evt.target.closest("[data-bsf-image-select]");
    if (!imageEl) {
      return;
    }
    const fieldName = evt.target.closest("[data-bsf-image]").dataset.bsfImage;

    const ev = new Event("bsf-image-chooser-selected");
    ev.detail = {
      fieldName,
      value: imageEl.dataset.bsfImageSelect,
      author: imageEl.dataset.bsfImageAuthor,
      imageType: imageEl.dataset.bsfImageTypeId,
    };
    if (imageEl.dataset.bsfImageAuthorPk) {
      ev.detail["author"] = {
        id: `${imageEl.dataset.bsfImageAuthorPk}:${imageEl.dataset.bsfImageAuthorName}`,
        text: imageEl.dataset.bsfImageAuthorName,
      };
    }
    document.dispatchEvent(ev);
  });
}
