import { toggleField } from "./helpers";

export default function initNewApplicationForm(form) {
  if (!form) {
    return;
  }
  form.querySelectorAll("[data-toggle-fields]").forEach((el) => {
    const toggleFields = JSON.parse(el.dataset.toggleFields);
    const update = () => {
      const val = el.querySelector('option:checked').value
      Object.entries(toggleFields).forEach(([key, value]) => {
        value.forEach((id) => {
          toggleField(document.getElementById(id), val != key);
        });
      });
    };
    el.addEventListener("change", update);
    update();
  });
}
