export default function initTabs(el) {
  const selectEl = el.querySelector('[data-toggle="tab-select"]');
  const tabs = el.querySelectorAll('[data-toggle="tab"]');

  const selectTab = (target) => {
    const activeTab = el.querySelector(`[data-target="${target}"]`);
    const url = new URL(window.location);
    url.hash = target.substring(1);
    history.replaceState(null, null, url);

    // update selectEl value
    selectEl.selectedIndex = [...selectEl.options].findIndex(
      (item) => item.value === target
    );

    tabs.forEach((tab) => {
      tab.removeAttribute("aria-current");
      tab.classList.remove("active");
    });

    activeTab.setAttribute("aria-current", "page");
    activeTab.classList.add("active");

    el.querySelector(target).classList.add("active");

    // Hide the content of all other tabs
    const otherTargets = el.querySelectorAll(".tab-pane:not(" + target + ")");
    otherTargets.forEach((otherTarget) => {
      otherTarget.classList.remove("active");
    });

    // scroll into view
    el.scrollIntoView(true);
  };

  if (selectEl) {
    selectEl.addEventListener("change", () => {
      selectTab(selectEl.options[selectEl.selectedIndex].value);
    });
  }

  for (let tab of tabs) {
    tab.addEventListener("click", function (event) {
      event.preventDefault();
      selectTab(this.getAttribute("data-target"));
    });
  }

  // Select the target from hash or first tab
  const initialTarget = window.location.hash
    ? window.location.hash
    : tabs[0].getAttribute("data-target");
  selectTab(initialTarget);
  window.dispatchEvent(new Event("initial-tab-selected"));
}
