import $ from "jquery";
import {
  setSelect2Value,
  getRemoteFieldId,
  isOther,
} from "./select2";
import { closeModals } from "./modal";
import { fillField, getCompanyDetails, handleBsfCity, handleBsfCompany, handleBsfPerson, setValueIfFieldsExists, setupRelatedField, toggleField, toggleHidden } from "./helpers";
import handleProducers from "./producers";

function handleTitle() {
  const originalTitle = $("#id_basic-original_title");
  const originalTitleLanguage = $("#id_basic-original_title_language");
  const sl = originalTitleLanguage.attr("data-sl-language");
  const en = originalTitleLanguage.attr("data-en-language");
  const slTitle = $("#id_basic-sl_title");
  const enTitle = $("#id_basic-en_title");

  const handleTitleChange = () => {
    if (originalTitleLanguage.val() == sl) {
      slTitle.val(originalTitle.val());
      toggleField(slTitle, true);
      toggleField(enTitle, false);
    } else if (originalTitleLanguage.val() == en) {
      enTitle.val(originalTitle.val());
      toggleField(slTitle, false);
      toggleField(enTitle, true);
    } else {
      toggleField(slTitle, false);
      toggleField(enTitle, false);
    }
  };

  originalTitle.on("change", handleTitleChange);
  originalTitleLanguage.on("change", handleTitleChange);

  handleTitleChange();
}

async function copyBsfCompanyFields(el) {
  // asure that there is only one bsf-city in parent data-form
  const val = $(el).val();
  if (!val || isOther(val)) {
    return;
  }
  const form = $(el).closest("[data-form]");
  const cityField = form.find("[data-field-type=bsf-city]");
  if (cityField.length) {
    const company = await getCompanyDetails(getRemoteFieldId(val));
    setSelect2Value(cityField, company.city);
  }
}

function fieldsSelector(arr) {
  return arr.map((field) => `#id_${field}`).join(", ");
}

function disableFields(arr, disabled = true) {
  arr.forEach((el) => {
    toggleField($(`#id_${el}`), disabled);
  });
}

const FIELDS_PREMIERE = [
  "basic-premiere_date",
  "basic-premiere_date_accuracy",
  "basic-festival_projection",
];
const FIELDS_FESTIVAL = ["basic-festival_bsf", "basic-festival_name"];
const FIELDS_LOCATION = ["basic-premiere_location"];

function handlePremiere() {
  const isPremiere =
    $('input[name="basic-premiere"]:checked').val() !== "False";

  disableFields(FIELDS_PREMIERE, isPremiere);
  disableFields(FIELDS_FESTIVAL, isPremiere);
  disableFields(FIELDS_LOCATION, isPremiere);

  if (!isPremiere) {
    const isFestivalProjection = $("#id_basic-festival_projection").is(
      ":checked"
    );
    disableFields(FIELDS_FESTIVAL, !isFestivalProjection);
    disableFields(FIELDS_LOCATION, isFestivalProjection);
    disableFields(
      ["basic-festival_name"],
      !isOther($("#id_basic-festival_bsf").val())
    );
  }
}

function handleCategories() {
  const $el = $("#id_basic-categories");
  const isAnimated = $el.val().indexOf($el.attr("data-animated"));
  disableFields(
    ["technical_data-animations", "technical_data-animation_other"],
    isAnimated === -1
  );
}

function handleLegalEntityFields() {
  const isLegal = $("#id_applicant_legal_representative").is(":checked");
  disableFields(
    [
      "company",
      "company_name",
      "connect_bsf_account",
      "publish_on_bsf",
      "company_address",
      "company_zip_code",
      "company_city",
      "company_tel",
      "company_email",
      "company_url",
    ],
    !isLegal
  );
  if (isLegal) {
    handleBsfCompany($("#id_company").get(0));
  }
}

function handleContactData() {
  const contactPersonApplicant = $("#id_producers-contact_person_applicant").is(
    ":checked"
  );
  disableFields(
    [
      "producers-contact_person",
      "producers-contact_email",
      "producers-contact_tel",
    ],
    contactPersonApplicant
  );
}

export function initNewApplicationForm(form) {
  if (!form) {
    return;
  }
  const avmediaEl = $("#id_avmedia");
  const originalTitleEl = $("#id_original_title");
  const filmIsNotInDatabaseEl = $("#id_film_is_not_in_database");

  const handleDisabled = () => {
    if (avmediaEl.val()) {
      toggleField(originalTitleEl, true);
      toggleField(filmIsNotInDatabaseEl, true);
    } else {
      toggleField(filmIsNotInDatabaseEl, false);
      toggleField(originalTitleEl, !filmIsNotInDatabaseEl.is(":checked"));
      toggleField(avmediaEl, filmIsNotInDatabaseEl.is(":checked"));
    }
  };

  handleDisabled();
  avmediaEl.on("change", handleDisabled);
  filmIsNotInDatabaseEl.on("change", handleDisabled);
}

function disableImageField(el, value) {
  // delete eventual data-image in data-image-group
  const imageGroup = el.closest("[data-image-group]");
  if (imageGroup) {
    const image = imageGroup.querySelector("[data-image-field]");
    if (image) {
      if (value) {
        clearImage(image);
      }
      toggleField(image, value);
    }
  }
}

function disableBsfImageField(el, value) {
  // delete eventual data-image in data-image-group
  const imageGroup = el.closest("[data-image-group]");
  if (imageGroup) {
    const image = imageGroup.querySelector("[data-bsf-image-field]");
    const chooserBtn = imageGroup.querySelector(
      "[data-bsf-image-open-chooser]"
    );
    if (image) {
      if (value) {
        clearBsfImage(image);
      }
      $(image).attr("readonly", value);
      toggleField(chooserBtn, value);
    }
  }
}

function setPhotoAuthor(el, value) {
  const imageGroup = el.closest("[data-image-group]");
  if (imageGroup) {
    const authorField = imageGroup.querySelector("[data-photo-author]");
    if (authorField) {
      if (value) {
        setSelect2Value($(authorField), value);
      } else {
        $(authorField).val(null).trigger("change");
      }
    }
  }
}

function setImageType(el, value) {
  const imageGroup = el.closest("[data-image-group]");
  if (imageGroup) {
    const target = imageGroup.querySelector("[data-image-type]");
    if (target) {
      $(target).val(value).trigger("change");
    }
  }
}

function handleBsfImageChooser(event) {
  const field = document.querySelector(`input[name=${event.detail.fieldName}]`);
  const container = field.closest("[data-bsf-image]");
  const thumbnail = container.querySelector("[data-bsf-image-thumbnail]");
  field.value = event.detail.value;
  thumbnail.src = field.value.split("!")[1];
  thumbnail.parentNode.href = field.value.split("!")[2];
  toggleHidden(thumbnail, !thumbnail.src);
  toggleHidden(
    container.querySelector("[data-bsf-image-clear]"),
    !thumbnail.src
  );
  setPhotoAuthor(field, event.detail.author);
  setImageType(field, event.detail.imageType);
  closeModals();
  disableImageField(container, true);
}

function clearBsfImage(el) {
  const container = el.closest("[data-bsf-image]");
  container.querySelector("[data-bsf-image-field]").value = "";
  const thumbnail = container.querySelector("[data-bsf-image-thumbnail]");
  thumbnail.src = "";
  toggleHidden(thumbnail, true);
  toggleHidden(container.querySelector("[data-bsf-image-clear]"), true);
  disableImageField(container, false);
}

function clearImage(el) {
  const container = el.closest("[data-image]");
  const field = container.querySelector("[data-image-field]");
  field.value = "";
  setValueIfFieldsExists(
    container.querySelector("[data-image-field-delete]"),
    1
  );
  setValueIfFieldsExists(
    container.querySelector("[data-image-field-cached]"),
    ""
  );
  container.querySelector("[data-image-cached-label]")?.remove();
  handleImageField(field);
}

function handleImageField(el) {
  const container = el.closest("[data-image]");
  const field = container.querySelector("[data-image-field]");
  const thumbnail = container.querySelector("[data-image-thumbnail]");
  if (field.value) {
    setValueIfFieldsExists(
      container.querySelector("[data-image-field-delete]"),
      ""
    );
    const reader = new FileReader();
    reader.onload = function (e) {
      thumbnail.src = e.target.result;
    };
    reader.readAsDataURL(field.files[0]);
    toggleHidden(container.querySelector("[data-image-field]"), true);
    toggleHidden(container.querySelector("[data-image-clear]"), false);
    toggleHidden(thumbnail, false);
    disableBsfImageField(container, true);
  } else {
    toggleHidden(container.querySelector("[data-image-field]"), false);
    toggleHidden(container.querySelector("[data-image-clear]"), true);
    toggleHidden(thumbnail, true);
    disableBsfImageField(container, false);
  }
}

function handleImages() {
  document.querySelectorAll("[data-bsf-image-field]").forEach((el) => {
    if (el.value) {
      disableImageField(el, true);
    }
  });
  document.querySelectorAll("[data-image-clear]").forEach((el) => {
    if (!el.classList.contains("hidden")) {
      disableBsfImageField(el, true);
    }
  });
  document.addEventListener(
    "bsf-image-chooser-selected",
    handleBsfImageChooser
  );
  document.addEventListener("click", (event) =>
    event.target.matches("[data-bsf-image-clear]")
      ? clearBsfImage(event.target)
      : null
  );
  document.addEventListener("click", (event) =>
    event.target.matches("[data-image-clear]") ? clearImage(event.target) : null
  );
  document.addEventListener("change", (event) =>
    event.target.matches("[data-image-field]")
      ? handleImageField(event.target)
      : null
  );
}

function handleDirectorPhotoChooser(event) {
  let person = $(".formset-list [data-crew-function=director]")
    .val()
    ?.split(":")[0];
  if (!person || isOther(person)) {
    person = -1;
  }
  event.detail.parameters.person = person;
}

function handleSubtitleLanguages() {
  const hasSubtitles =
    $("[name=materials-has_subtitles]:checked").val() === "True";
  toggleField("#id_materials-subtitle_languages", !hasSubtitles);
}

function handleNoBaseCrew() {
  document.querySelectorAll("[data-no-base-crew]").forEach((el) => {
    const formset = document.querySelector(
      `[data-prefix=base_crew_${el.dataset.noBaseCrew}]`
    );
    function toggleFormset(checked) {
      formset.classList.toggle("hidden", checked);
      formset.querySelectorAll("select, input[type=text]").forEach((el) => {
        el.disabled = checked;
      });
      formset
        .querySelectorAll("select[data-field-type=bsf-person]")
        .forEach((el) => {
          handleBsfPerson(el);
        });
    }
    toggleFormset(el.checked);
    el.addEventListener("change", () => toggleFormset(el.checked));
  });
}

function copyValueAndTriggerChange(srcField, destField) {
  if (destField.tagName === "SELECT") {
    destField.innerHTML = "";

    Array.from(srcField.options).forEach((option) => {
      const newOption = new Option(
        option.text,
        option.value,
        option.defaultSelected,
        option.selected
      );
      destField.add(newOption);
    });
  } else {
    destField.value = srcField.value;
  }

  const event = new Event("change", {
    bubbles: true,
    cancelable: true,
  });
  destField.dispatchEvent(event);
}

function handleCopySales() {
  const copySalesButton = document.getElementById("copy-sales-button");
  const distributionNumFormsEl = document.getElementById(
    "id_distribution-TOTAL_FORMS"
  );
  const salesNumFormsEl = document.getElementById("id_sales-TOTAL_FORMS");
  const addSalesButton = document.querySelector(
    "#sales-section .formset-add-empty"
  );

  const toggleCopyButton = () => {
    copySalesButton.classList.toggle(
      "!hidden",
      !parseInt(distributionNumFormsEl.value)
    );
  };
  distributionNumFormsEl.addEventListener("change", toggleCopyButton);
  toggleCopyButton();

  copySalesButton.addEventListener("click", () => {
    const newForms = +distributionNumFormsEl.value - +salesNumFormsEl.value;
    for (let i = 0; i < newForms; i++) {
      addSalesButton.click();
    }
    for (let i = 0; i < +distributionNumFormsEl.value; i++) {
      for (const field of [
        "bsf_company",
        "name",
        "city",
        "city_name",
        "country",
        "contact_person",
        "contact_email",
      ]) {
        const destField = document.getElementById(`id_sales-${i}-${field}`);
        const srcField = document.getElementById(
          `id_distribution-${i}-${field}`
        );
        copyValueAndTriggerChange(srcField, destField);
      }
    }
  });
}

export function initPrijavnica() {
  handleImages();

  setupRelatedField(
    $("#id_technical_data-without_dialogs"),
    $("#id_technical_data-dialogue_languages_comment")
  );

  $("[name=materials-has_subtitles]").on("change", handleSubtitleLanguages);
  handleSubtitleLanguages();

  document
    .getElementById("bsf-image-chooser_id_materials-director_photo_bsf")
    .addEventListener("htmx:configRequest", handleDirectorPhotoChooser);

  setupRelatedField(
    $("#id_basic-premiere_date"),
    $("#id_basic-premiere_date_accuracy"),
    true,
    "keyup"
  );

  handleLegalEntityFields();
  $("#id_applicant_legal_representative").on("change", handleLegalEntityFields);

  $("#id_company").on("select2:select", async (evt) => {
    if (isOther(evt.params.data.id)) {
      return;
    }
    const company = await getCompanyDetails(
      getRemoteFieldId(evt.params.data.id)
    );
    fillField($("#id_company_address"), company.address, true);
    fillField($("#id_company_zip_code"), company.zip_code, true);
    setSelect2Value($("#id_company_city"), company.city, true);
    fillField($("#id_company_tel"), company.phone, true);
    fillField($("#id_company_email"), company.email, true);
    fillField($("#id_company_url"), company.website, true);
  });

  handleTitle();

  handleProducers();

  $(
    "#id_producers-contact_person_applicant, #id_applicant_legal_representative"
  ).on("change", handleContactData);
  handleContactData();

  //$("#id_producers-zip_code").on("change", (evt) = {
  //// NOTE: no zip_code exists for cities in bsf
  //});

  $(document).on("change", "[data-field-type=bsf-company]", (evt) => {
    handleBsfCompany(evt.target);
    copyBsfCompanyFields(evt.target);
  });
  $("[data-field-type=bsf-company]").each((index, el) => handleBsfCompany(el));

  const personFieldsMappings = [
    { field: "[data-field-type=bsf-person]", selector: "[data-person-field]" },
    {
      field: "#id_materials-director_photo_author",
      selector: "#id_materials-director_photo_author_name",
    },
    {
      field: "#id_materials-poster_photo_author",
      selector: "#id_materials-poster_photo_author_name",
    },
  ];
  personFieldsMappings.forEach(({ field, selector }) => {
    $(document).on("change", field, (evt) =>
      handleBsfPerson(evt.target, selector)
    );
    $(document).on("select2:select", field, (evt) =>
      handleBsfPerson(evt.target, selector, evt.params.data)
    );
    $(document).on("select2:clear", field, (evt) => {
      handleBsfPerson(evt.target, selector);
    });
    $(field).each((index, el) => handleBsfPerson(el, selector));
  });

  $(document).on("select2:select", "[data-field-type=bsf-city]", (evt) => {
    handleBsfCity(evt.target, evt.params.data);
  });
  $(document).on("select2:clear", "[data-field-type=bsf-city]", (evt) => {
    handleBsfCity(evt.target);
  });
  $("[data-field-type=bsf-city]").each((index, el) => handleBsfCity(el));

  $(
    fieldsSelector([
      "basic-premiere_0",
      "basic-premiere_1",
      "basic-festival_projection",
      "basic-festival_bsf",
    ])
  ).on("change", handlePremiere);
  handlePremiere();

  $("#id_basic-categories").on("change", handleCategories);
  handleCategories();

  handleNoBaseCrew();

  handleCopySales();
}
