/* global htmx */

import $ from "jquery";
import { initializeSelect2, initializeSelect2WithAjax } from "./select2";
import { initFormset } from "./formsetHelpers";
import { initNewApplicationForm, initPrijavnica } from "./prijavnica";
import initTextCounter from "./textCounter";
import initTabs from "./tabs";
import initBsfImageChooser from "./bsfImageChooser";
import initModal, { closeModals } from "./modal";
import initializeTooltip from "./tooltips";
import handleDirtyFields from "./dirtyFields";
import { setupEnableOther } from "./helpers";
import initApplicationListEditForm from "./applicationListEdit";

export function initWidgets(target) {
  $("select.select2[data-url]", target).each(function (_index, el) {
    initializeSelect2WithAjax(el);
  });

  $("select.select2:not([data-url])", target).each(function (_index, el) {
    initializeSelect2(el);
  });

  $(".formset", target).each(function (_index, el) {
    initFormset(el);
  });

  $("[data-max-length]", target).each(function (_index, el) {
    initTextCounter(el);
  });
  $(".tabs", target).each(function (_index, el) {
    initTabs(el);
  });
  $("[data-enable-other]", target).each(function (_index, el) {
    setupEnableOther(el);
  });

  //INITIALIZE tooltips
  $("[data-toggle=tooltip]", target).each(function (_index, el) {
    initializeTooltip(el);
  });

  $("[data-unsaved-changes-message]", target).each(function (_index, el) {
    handleDirtyFields(el);
  });
}

$(() => {
  initWidgets(document);
  initBsfImageChooser();
  initModal();

  const form = $("#prijavnica").get(0);
  if (form) {
    initPrijavnica(form);
  }

  initNewApplicationForm(document.getElementById("new-application-form"));
  initApplicationListEditForm(document.getElementById("application-list-edit"));

  $(document).on("submit", "form:not([data-custom-submit])", function () {
    setTimeout(() => {
      $("button[type=submit], input[type=submit]", this).attr(
        "disabled",
        "disabled"
      );
    }, 0);
  });
});

//NOTE: workaround for https://github.com/select2/select2/issues/5993
$(document).on("select2:open", () => {
  document
    .querySelector(".select2-container--open .select2-search__field")
    .focus();
});

window.addEventListener("elementAdded", (ev) => {
  initWidgets(ev.detail);
  htmx.process(ev.detail);
});

function scrollToElementWithOffset(element) {
    const header = document.querySelector('.sm\\:sticky'); // Adjust the selector as needed
    const headerHeight = header ? header.offsetHeight : 0;
    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition - headerHeight - 50;

    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
    });
}
window.scrollToElementWithOffset = scrollToElementWithOffset;

window.addEventListener("initial-tab-selected", () => {
  const id = new URLSearchParams(window.location.search).get('focus')
  if (id) {
    const el = document.getElementById(id);
    if (el) {
      window.setTimeout(() => {
        scrollToElementWithOffset(el);
      }, 100);
    } else {
      console.warn(`Element with id ${id} not found`);
    }
  }
});

window.$ = $;

window.htmx = htmx;

window.app = { closeModals };
