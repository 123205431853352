import $ from "jquery";
import {
  addAndSelectOption,
  isOther,
} from "./select2";

export function toggleField(el, disabled) {
  $(el).attr("disabled", disabled);
  $(el).closest(".form-group").toggleClass("form-group-disabled", disabled);
}

export function getValueOrChecked(el) {
  if ($(el).attr("type") === "checkbox") {
    return $(el).prop("checked");
  } else {
    return $(el).val();
  }
}

export function toggleHidden(el, hide) {
  if (hide) {
    el.classList.add("hidden");
  } else {
    el.classList.remove("hidden");
  }
}

export function setValueIfFieldsExists(el, value) {
  if (el) {
    el.value = value;
  }
}

export function setupEnableOther(el) {
  const pk = el.dataset.enableOther;
  const target = $(`[data-enable-source=${el.id}]`);
  $(el).on("change", () => {
    toggleField(target, !el.disabled && $(el).val() != pk);
  });
  toggleField(target, !el.disabled && $(el).val() != pk);
}

function disableAndHideFields(elements, disabled) {
  elements.forEach((el) => {
    //console.log('disableAndHideFields', disabled, el);
    $(el).attr("disabled", disabled);
    $(el).closest(".form-group").toggle(!disabled);
    $(el).closest(".form-group").toggleClass("form-group-disabled", disabled);
  });
}

export function checkboxToggleFields(el) {
  const toggleFields = JSON.parse(el.dataset.toggleFields);
  const checked = toggleFields.checked.map((id) => document.getElementById(id));
  const unchecked = toggleFields.unchecked.map((id) =>
    document.getElementById(id)
  );
  let disableFields;
  let enableFields;
  if (el.checked) {
    disableFields = unchecked;
    enableFields = checked;
  } else {
    disableFields = checked;
    enableFields = unchecked;
  }
  disableAndHideFields(disableFields, true);
  disableAndHideFields(enableFields, false);
}


export function handleBsfCompany(el) {
  const otherDisabled = !isOther($(el).val());
  const form = $(el).closest("[data-form]");
  let target;
  if (el.dataset.otherFieldSelector) {
    target = $(el.dataset.otherFieldSelector);
  } else {
    target = form.find("[data-field-type=company-field]");
  }
  toggleField(target, otherDisabled);
}


export function handleBsfPerson(el, fieldSelector = null, data = null) {
  fieldSelector = fieldSelector || "[data-person-field]";
  const otherDisabled = !isOther($(el).val());
  const form = $(el).closest("[data-form]");
  toggleField(form.find(fieldSelector), otherDisabled);
  toggleField(form.find("[data-field-type=person-field]"), otherDisabled);
  if (el.id.match(/-bsf_person$/)) {
    const genderField = document.getElementById(
      el.id.replace(/-bsf_person$/, "-gender")
    );
    const genderSyncedField = document.getElementById(
      el.id.replace(/-bsf_person$/, "-gender_synced")
    );
    if (genderField && genderSyncedField) {
      if (data) {
        genderField.value = data.gender;
        genderSyncedField.value = data.gender || "";
      }
      toggleField(genderField, Boolean(genderSyncedField.value));
    }
  }
}

export function handleBsfCity(el, data) {
  const value = $(el).val();
  const form = $(el).closest("[data-form]");
  toggleField(form.find("[data-field-type=city-name]"), !isOther(value));
  //toggleField(form.find("[data-field-type=country]"), !isOther(value));
  if (data) {
    if (data.country) {
      const countryEl = form.find("[data-field-type=country]");
      if (countryEl.length) {
        addAndSelectOption(countryEl, data.country);
      }
    }
  }
}

export function fillField(el, value, overwrite = false) {
  if (!overwrite && el.val()) {
    return;
  }
  el.val(value || "");
}

function transformRemoteFieldId(val) {
  // id => id:text
  if (!val || !val.id) {
    return val;
  }
  return {
    ...val,
    id: `${val.id}:${val.text}`,
  };
}

export async function getCompanyDetails(id) {
  const response = await fetch(`/api/v1/bsf/company/${id}/`);
  const data = await response.json();
  return {
    ...data,
    country: transformRemoteFieldId(data["country"]),
    city: transformRemoteFieldId(data["city"]),
  };
}


export function disableRelatedField(el, el2, whenEmpty = false) {
  const value = getValueOrChecked(el);
  const disabled = whenEmpty ? !value : !!value;
  toggleField(el2, disabled);
}

export function setupRelatedField(el, el2, whenEmpty = false, event = null) {
  if (!event) {
    event = "change";
  }
  el.on(event, () => {
    disableRelatedField(el, el2, whenEmpty);
  });
  disableRelatedField(el, el2, whenEmpty);
}

