import { createPopper } from '@popperjs/core'
import { htmlToElement } from './htmlUtils'

const storage = new WeakMap()

const BASE_CLASSES = {
  tooltip: 'tooltip',
  popover: 'popover',
}

export default function initializeTooltip(button) {
  if (storage.has(button)) {
    return
  }
  const baseClass = BASE_CLASSES[button.dataset.toggle]
  const tooltip = htmlToElement(`
      <div class="${baseClass}" role="tooltip">
        <div class="popper__arrow" data-popper-arrow></div>
        ${button.title}
      </div>
      `)
  button.append(tooltip)
  button.title = ''

  const popperInstance = createPopper(button, tooltip, {
    placement: button.dataset.popperPlacement || 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  })

  storage.set(button, popperInstance)

  function show() {
    // Make the tooltip visible
    tooltip.setAttribute('data-show', '')

    // Enable the event listeners
    popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: true },
      ],
    }))

    // Update its position
    popperInstance.update()
  }

  function hide() {
    // Hide the tooltip
    tooltip.removeAttribute('data-show')

    // Disable the event listeners
    popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: false },
      ],
    }))
  }

  const showEvents = ['mouseenter', 'focus']
  const hideEvents = ['mouseleave', 'blur']

  showEvents.forEach((event) => {
    button.addEventListener(event, show)
  })

  hideEvents.forEach((event) => {
    button.addEventListener(event, hide)
  })
}
