export default function initTextCounter(el) {
  const counterEl = document.createElement('div')
  el.after(counterEl)
  const count = el.dataset.maxLength
  const warningCount = count * 0.8

  const showCounter = () => {
    const num = el.value.replace(/\s/g, '').length
    counterEl.innerText = `${num}/${count}`
    counterEl.className = "mt-1 text-sm"
    if (num > count) {
      counterEl.classList.add('text-red-600')
    } else if (el.value.length > warningCount) {
      counterEl.classList.add('text-yellow-600')
    } else {
      counterEl.classList.add('text-gray-500')
    }
  }

  el.addEventListener('change', showCounter)
  el.addEventListener('keyup', showCounter)
  showCounter()
}
