import Sortable from "sortablejs";

function reorder(formset) {
  formset.querySelectorAll("[data-order]").forEach((input, index) => {
    input.value = index + 1;
  });
}

export function initFormset(formset) {
  const formsetList = formset.querySelector(".formset-list");
  const sortableList = formset.querySelector(".sortable");
  const addEmpty = formset.querySelector(".formset-add-empty");
  const prefix = formset.dataset.prefix;
  const totalFormsInput = formset.querySelector(`#id_${prefix}-TOTAL_FORMS`);

  //init sortable if sortableList exists
  if (sortableList) {
    /* eslint-disable no-new */
    new Sortable(sortableList, {
      handle: ".handle",
      animation: 150,
      ghostClass: "bg-blue-100",
      onEnd: (evt) => {
        reorder(sortableList);
        evt.target.closest("form").dispatchEvent(new Event("input"));
      },
    });
  }

  // copy empty form and add it to formset
  if (addEmpty) {
    addEmpty.addEventListener("click", (evt) => {
      evt.preventDefault();

      const formIndex = parseInt(totalFormsInput.value);
      const emptyForm = formset.querySelector(".empty-form").children[0];
      const newForm = emptyForm.cloneNode(true);
      newForm.innerHTML = newForm.innerHTML.replace(/__prefix__/g, formIndex);
      totalFormsInput.value = formIndex + 1;
      totalFormsInput.dispatchEvent(new Event("change"));
      formsetList.appendChild(newForm);

      reorder(sortableList);

      // TODO: focus select2 or other input
      //newForm.querySelector("input").focus();

      const event = new Event("elementAdded");
      event.detail = newForm;
      window.dispatchEvent(event);
      evt.target.closest("form").dispatchEvent(new Event("input"));
    });
  }

  // delete action is element with .delete-field
  formsetList.addEventListener("click", (evt) => {
    if (!evt.target.closest("[data-delete]")) {
      return;
    }
    evt.preventDefault();
    evt.target.closest("[data-form]").remove();
    totalFormsInput.value = parseInt(totalFormsInput.value) - 1;
    totalFormsInput.dispatchEvent(new Event("change"));
  });
}
