export default function handleDirtyFields(form) {
  const dirtyMessageEl = document.getElementById("dirty-message");
  let isDirty =
    dirtyMessageEl && !dirtyMessageEl.classList.contains("opacity-0");
  form.addEventListener("input", () => {
    isDirty = true;
    if (dirtyMessageEl) {
      dirtyMessageEl.classList.remove("opacity-0");
      dirtyMessageEl.classList.add("opacity-100");
    }
  });
  form.addEventListener("submit", () => {
    isDirty = false;
  });

  window.addEventListener("beforeunload", function (event) {
    if (isDirty) {
      event.preventDefault();
      event.returnValue = form.dataset.unsavedChangesMessage;
    }
  });
}
